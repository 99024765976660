import React from 'react';
import HeaderStyled from '../styles/HeaderStyled';

function Header() {
  return (
    <HeaderStyled>
      <h1>ryan albert miller</h1>
    </HeaderStyled>
  );
}

export default Header;
