import styled from 'styled-components';

const FooterStyled = styled.footer `
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5vh;
  text-align: center;
  margin-top: 1vh;

  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 1.5em;
`;

export default FooterStyled;
