export const sections = [
  {
    id: 1,
    title: 'bio'
  },
  {
    id: 2,
    title: 'listen'
  },
  {
    id: 3,
    title: 'press'
  },
  {
    id: 4,
    title: 'contact',
    content: 'ryanalbertmiller@gmail.com',
    icon: 'src/assets/icons8-instagram-100.png'
  }
];
